<template>
  <v-container class="fill-height" fluid>
    <v-row>
      <v-col sm="4" cols="12" md="4" lg="3">
        <router-link tag="a" to="/admin/organisation-details">
          <material-stats-card
            color="#156a59"
            icon="mdi-domain"
            title="Organisation details"
            sub-icon="mdi-tag"
          />
        </router-link>
      </v-col>
      <v-col sm="4" cols="12" md="4" lg="3">
        <router-link tag="a" to="/admin/organisation-data">
          <material-stats-card
            color="#156a59"
            icon="mdi-database"
            title="Organsation Data"
            sub-icon="mdi-tag"
          />
        </router-link>
      </v-col>
      <v-col sm="4" cols="12" md="4" lg="3">
        <router-link tag="a" to="/admin/defaults">
          <material-stats-card
            color="#156a59"
            icon="mdi-cogs"
            title="System Defaults"
            sub-icon="mdi-tag"
          />
        </router-link>
      </v-col>
      <v-col sm="4" cols="12" md="4" lg="3">
        <router-link tag="a" to="/admin/export-data">
          <material-stats-card
            color="#156a59"
            icon="mdi-hazard-lights"
            title="Export data"
            sub-icon="mdi-tag"
          />
        </router-link>
      </v-col>
      <v-col sm="4" cols="12" md="4" lg="3">
        <router-link tag="a" to="/admin/notifications-data">
          <material-stats-card
            color="#156a59"
            icon="mdi-bell-outline"
            title="Notifications"
            sub-icon="mdi-tag"
          />
        </router-link>
      </v-col>
    </v-row>
  </v-container>
</template>
<style>
.v-card--material-stats:hover {
  border-top: 4px solid #156a59;
}
</style>
<script>
export default {
  data() {
    return {
      dailySalesChart: {
        data: {
          labels: ["M", "T", "W", "T", "F", "S", "S"],
          series: [[12, 17, 7, 17, 23, 18, 38]]
        },
        options: {
          lineSmooth: this.$chartist.Interpolation.cardinal({
            tension: 0
          }),
          low: 0,
          high: 50,
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          }
        }
      },
      dataCompletedTasksChart: {
        data: {
          labels: ["12am", "3pm", "6pm", "9pm", "12pm", "3am", "6am", "9am"],
          series: [[230, 750, 450, 300, 280, 240, 200, 190]]
        },
        options: {
          lineSmooth: this.$chartist.Interpolation.cardinal({
            tension: 0
          }),
          low: 0,
          high: 1000,
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          }
        }
      },
      emailsSubscriptionChart: {
        data: {
          labels: [
            "Ja",
            "Fe",
            "Ma",
            "Ap",
            "Mai",
            "Ju",
            "Jul",
            "Au",
            "Se",
            "Oc",
            "No",
            "De"
          ],
          series: [[542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895]]
        },
        options: {
          axisX: {
            showGrid: false
          },
          low: 0,
          high: 1000,
          chartPadding: {
            top: 0,
            right: 5,
            bottom: 0,
            left: 0
          }
        },
        responsiveOptions: [
          [
            "screen and (max-width: 640px)",
            {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc: function(value) {
                  return value[0];
                }
              }
            }
          ]
        ]
      },
      headers: [
        {
          sortable: false,
          text: "ID",
          value: "id"
        },
        {
          sortable: false,
          text: "Name",
          value: "name"
        },
        {
          sortable: false,
          text: "Salary",
          value: "salary",
          align: "right"
        },
        {
          sortable: false,
          text: "Country",
          value: "country",
          align: "right"
        },
        {
          sortable: false,
          text: "City",
          value: "city",
          align: "right"
        }
      ],
      items: [
        {
          name: "Dakota Rice",
          country: "Niger",
          city: "Oud-Tunrhout",
          salary: "$35,738"
        },
        {
          name: "Minerva Hooper",
          country: "Curaçao",
          city: "Sinaai-Waas",
          salary: "$23,738"
        },
        {
          name: "Sage Rodriguez",
          country: "Netherlands",
          city: "Overland Park",
          salary: "$56,142"
        },
        {
          name: "Philip Chanley",
          country: "Korea, South",
          city: "Gloucester",
          salary: "$38,735"
        },
        {
          name: "Doris Greene",
          country: "Malawi",
          city: "Feldkirchen in Kārnten",
          salary: "$63,542"
        }
      ],
      tabs: 0,
      list: {
        0: false,
        1: false,
        2: false
      }
    };
  },
  methods: {
    complete(index) {
      this.list[index] = !this.list[index];
    }
  }
};
</script>
